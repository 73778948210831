import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import { ReactComponent as Kwock } from './kwock2.svg'
import { Grid, SvgIcon } from '@mui/material'

function createData(name: string,  status: string, steps: number, kwockCount: number) {
  return { name,  status, steps, kwockCount }
}

const rows: { name: string; status: string; steps: number, kwockCount: number }[] = [
  createData('Will',  'Platinum', 129650, 23),
  createData('Sam','Gold', 25250, 7),
  createData('Cassidy',  'Gold', 25900, 6),
  createData('Lauren', 'Silver', 11250, 4),
  createData('Stuie',  'Silver', 26000, 2),
  createData('Rene',  'Silver', 23000, 3),
  createData('Piko',  'Silver', 28000, 3),
  createData('Brapse',  'Bronze', 9700, 4),
  createData('Callum',  'Bronze', 4000, 1),
  createData('Euge',  'Bronze', 4000, 1),
  createData('Gabriel',  'Bronze', 4000, 1),
  createData('Noam',  'Bronze', 4000, 1),
  createData('Zaki',  'Bronze', 8500, 2),
  createData('Duke',  'Gold', 36000, 2),
  createData('Marko',  'Gold', 24500,3),
  createData('Tux',  'Gold',  43000,7),
  createData('Eli',  'Gold', 28000,3),
  createData('Jill', 'Gold', 30500,6),
  createData('Jesse',  'Bronze', 0,0),
  createData('Emily',  'Bronze', 1500,1),
  createData('Laura', 'Bronze', 3000, 1),
  createData('XLA',  'Bronze', 0,0),
  createData('Dean',  'Bronze', 0,0),
  createData('Dieter', 'Bronze', 7000,1),
  createData('Fraser', 'Bronze', 7000,1),
  createData('Ozan',  'Bronze', 0,0),
  createData('Nina',  'Bronze', 4000,2),
  createData('Bertie',  'Bronze', 0,0),
  createData('Anna',  'Bronze', 0,0),
  createData('Billy',  'Bronze', 0,0),
  createData('Dev',  'Bronze', 0,0),
  createData('James',  'Bronze', 0,0),
  createData('Onur',  'Bronze', 6500,1),
  createData('Sunny',  'Bronze', 0,0),
  createData('Eka',  'Bronze', 6000,2),
  createData('Abbey',  'Bronze', 0,0),
  createData('Ismail',  'Bronze', 0,0),
  createData('Afra',  'Bronze', 0,0),
  createData('Jenny',  'Gold', 5200,1),
  createData('Vivi',  'Bronze', 2000,1),
].sort((a, b) => b.steps - a.steps);

export default function List() {
  return (
    <>
      <AppBar>
        <Container sx={{ backgroundColor: '#000000' }}>
          <Toolbar disableGutters sx={{ backgroundColor: '#000000' }}>
            <Grid item container justifyContent={'center'} alignItems="center">
              <Box sx={{ pr: 2 }} alignItems="center" justifyContent="center">
                <Kwock style={{ height: 100, width: 100 }} />
              </Box>

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  // mr: 2,
                  // display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  // fontWeight: {xs: 200, md: 700},
                  fontSize: { xs: '16px', md: '16px' },
                  // letterSpacing: '.3rem',
                  textDecoration: 'none',
                  color: '#ffbf00',
                }}
              >
                Pancakes Kwock Club Leaderboard
              </Typography>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <TableContainer
        component={Paper}
        sx={{ '&::-webkit-scrollbar': { display: 'none' }, mt: 16 }}
      >
        <Box sx={{ height: '70vh' }}>
          <Table
            sx={{ minWidth: 350, backgroundColor: '#282828', color: '#ffbf00' }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead sx={{ color: '#ffbf00' }}>
              <TableRow>
                <TableCell sx={{ color: '#ffbf00' }}>Member</TableCell>
                <TableCell align="right" sx={{ color: '#ffbf00' }}>
                  Rank
                </TableCell>
                <TableCell align="right" sx={{ color: '#ffbf00' }}>
                  Tier
                </TableCell>
                <TableCell align="right" sx={{ color: '#ffbf00' }}>
                  Steps
                </TableCell>
                <TableCell align="right" sx={{ color: '#ffbf00' }}>
                  Kwocks
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: '#ffbf00' }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="right" sx={{ color: '#ffbf00' }}>
                    {index}
                  </TableCell>
                  <TableCell align="right" sx={{ color: '#ffbf00' }}>
                    {row.status}
                  </TableCell>
                  <TableCell align="right" sx={{ color: '#ffbf00' }}>
                    {row.steps}
                  </TableCell>
                  <TableCell align="right" sx={{ color: '#ffbf00' }}>
                    {row.kwockCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <SvgIcon>
        <Kwock />
      </SvgIcon>
    </>
  )
}
