import React from 'react'
import {
  Box,
  CssBaseline,
  Paper,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'

import List from './List'

function App() {
  // define theme
  const theme = createTheme({
    palette: {
      primary: {
        light: '#000000 ',
        main: '#000000',
        dark: '#005db0',
        contrastText: '#000',
      },
      secondary: {
        main: '#4db6ac',
        light: '#ffbf00',
        dark: '#00867d',
        contrastText: '#000',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          backgroundColor: '#000000',
          backgroundImage: `url("https://www.transparenttextures.com/patterns/dimension.png")`,
        }}
      >
        <Paper
          // elevation={3}ß
          sx={{ padding: '1rem', backgroundColor: '#000000' }}
        >
          <List />
        </Paper>
      </Box>
    </ThemeProvider>
  )
}

export default App
